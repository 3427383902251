const dataset = document.getElementById("maalgruppeScript").dataset

const articleType = dataset.articletype
const articleId = dataset.id
const getArticleByMalgruppeService = dataset.serviceurl
const maalgruppeSelected = dataset.maalgruppeselected

$(document).ready(function () {
  if (articleType !== 'default') {
    $("#breadcrumb-menu").appendTo("#top-page-article")
  }

  $('#articleSelector').val(maalgruppeSelected)

  if (articleType === 'root') {
    $('#articleSelector').val(maalgruppeSelected || null)
    updateLinksYrk(maalgruppeSelected)
  }
})

function updateLinksYrk(maalgruppeSelected) {
  $.ajax({
    type: 'POST',
    url: getArticleByMalgruppeService,
    dataType: 'json',
    processData: true,
    data: {
      rootId: articleId,
      malgruppeName: maalgruppeSelected
    },
    success: function (data) {
      $('.linksYrk').each(function () {
        var name = $(this).attr('data-name')
        $(this).attr('href', data[name])
      })
    }
  })
}

$('#articleSelector').change(function () {
  const selectedValue = $(this).val()
  document.cookie = `malgruppe=${selectedValue}; SameSite=Lax; path=/`


  if (articleType === 'leaf') {
    window.location.href = $(this)[0].selectedOptions[0].attributes['data-url'].value
  } else if (articleType === 'root') {
    updateLinksYrk(selectedValue)
  }
});
